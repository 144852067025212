import React, { useEffect, useState } from 'react'

import { ArPlatformProducts } from 'src/components/ArPlatformProducts'
import { ArPlatformProductsTilesMenu } from 'src/components/ArPlatformProductsTilesMenu'
import { ArRichCtaWithBg } from 'src/components/ArRichCtaWithBg'
import { ArSeo } from 'src/components/ArSeo'
import HomepageFooterCtaBg from 'src/assets/images/HomepageFooterCtaBg.jpg'
import HomepageFooterCtaBgWebp from 'src/assets/images/HomepageFooterCtaBg.webp'
import { METRICS } from 'src/config/metrics'
import PlatformPageHeroBackground from 'src/assets/images/PlatformPageHeroBackground.jpg'
import PlatformPageHeroBackgroundWebp from 'src/assets/images/PlatformPageHeroBackground.webp'
import { ROUTES } from 'src/config/routes'
import { isWebpSupported } from 'src/utils/isWebpSupported'
import { navigate } from 'gatsby'

const PlatformPage = () => {
  const [footerCtaBgUrl, setFooterCtaBgUrl] = useState<string>()
  const [heroBackgroundUrl, setHeroBackgroundUrl] = useState<string>()

  useEffect(() => {
    setFooterCtaBgUrl(isWebpSupported() ? HomepageFooterCtaBgWebp : HomepageFooterCtaBg)
    setHeroBackgroundUrl(isWebpSupported() ? PlatformPageHeroBackgroundWebp : PlatformPageHeroBackground)
  }, [])

  return (
    <>
      <ArSeo route={ROUTES.platform()} />
      <ArRichCtaWithBg
        backgroundUrl={heroBackgroundUrl}
        heights={{
          mobile: METRICS.PlatformHeroHeightMobile,
          desktop: METRICS.PlatformHeroHeightDesktop,
        }}
        title={['Write better risks, faster']}
        subtitle="An all-in-one underwriting platform for the insurer of the future."
        actionButtons={[
          {
            title: 'Book a demo',
            onClick: () => {
              navigate(ROUTES.contact())
            },
            theme: 'gradient',
          },
        ]}
        withDarkOverlayer
      />
      <ArPlatformProductsTilesMenu />
      <ArPlatformProducts />
      <ArRichCtaWithBg
        backgroundUrl={footerCtaBgUrl}
        heights={{
          mobile: METRICS.FooterHeightMobile,
          desktop: METRICS.FooterHeightMobile,
        }}
        title={[`Want to learn more?`]}
        subtitle="Book a chat with us to discover how we could transform your insurance business."
        actionButtons={[
          {
            title: 'Contact us',
            onClick: () => {
              navigate(ROUTES.contact())
            },
            theme: 'dark',
          },
        ]}
        withDarkGradient
      />
    </>
  )
}

export default PlatformPage
