import * as s from './ArTag.module.scss'

import React from 'react'
import { TArTagProps } from './ArTag.types'
import classNames from 'classnames'

export const ArTag: React.FC<TArTagProps> = ({ className, children, theme, small, style, onClick }) => {
  const tagClass = classNames(s.container, className, {
    [s.small]: small,
    [s.themeRed]: theme === 'red',
    [s.themeBlue]: theme === 'blue',
    [s.themeGray]: theme === 'gray' || !theme,
  })

  if (onClick) {
    return (
      <button className={tagClass} style={style} onClick={onClick}>
        {children}
      </button>
    )
  }

  return (
    <div className={tagClass} style={style}>
      {children}
    </div>
  )
}
