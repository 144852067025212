import * as s from './ArPlatformProducts.module.scss'

import { ArExtraFeatures, TArExtraFeaturesProps } from 'src/components/ArExtraFeatures'
import React, { useEffect, useState } from 'react'
import { TRichText, parseRichText } from 'src/utils/parseRichText'

import { ArContainer } from 'src/components/ArContainer'
import { ArTag } from 'src/components/ArTag'
import { PLATFORM_PRODUCTS } from 'src/config/platformProducts'
import { TArPlatformProductsProps } from './ArPlatformProducts.types'
import classNames from 'classnames'
import { isWebpSupported } from 'src/utils/isWebpSupported'

type TTextWithImageProps = {
  title: string
  paragraph: TRichText
  image: {
    png: string
    webp: string
  }
  list?: string[]
  tagText?: string
  textOnTheLeft?: boolean
}

const TextWithImage: React.FC<TTextWithImageProps> = ({ title, paragraph, list, tagText, textOnTheLeft, image }) => {
  const containerClass = classNames(s.textWithImage, {
    [s.textOnTheLeft]: textOnTheLeft,
  })

  const [imageUrl, setImageUrl] = useState<string>()

  useEffect(() => {
    setImageUrl(isWebpSupported() ? image.webp : image.png)
  }, [image.png, image.webp])

  return (
    <div className={containerClass}>
      <div className={s.content}>
        <div>{/* I'm a plug for flexbox */}</div>
        <div className={s.contentWrapper}>
          {tagText && (
            <ArTag className={s.tag} theme="red" small>
              {tagText}
            </ArTag>
          )}
          <h2>{title}</h2>
          <div dangerouslySetInnerHTML={{ __html: parseRichText(paragraph) }} />
          {list && list.length > 0 && (
            <ul>
              {list?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <img className={s.image} src={imageUrl} alt={title} loading="lazy" />
    </div>
  )
}

export const ArPlatformProducts: React.FC<TArPlatformProductsProps> = () => {
  return (
    <div className={s.container}>
      {PLATFORM_PRODUCTS.map(({ className, textWithImageProps, extraFeaturesProps, id }, i) => (
        <section key={i} className={s[className]} id={id}>
          <ArContainer full>
            {textWithImageProps && <TextWithImage {...(textWithImageProps as TTextWithImageProps)} />}
            {extraFeaturesProps && <ArExtraFeatures {...(extraFeaturesProps as TArExtraFeaturesProps)} />}
          </ArContainer>
        </section>
      ))}
    </div>
  )
}
