import * as s from './ArPlatformProductsTilesMenu.module.scss'

import React, { useState } from 'react'
import { animateScroll, scroller } from 'react-scroll'

import { METRICS } from 'src/config/metrics'
import { PLATFROM_SECTIONS } from 'src/config/platformSections'
import SvgArrowRightInCircle from 'src/assets/svg/arrow-right-in-circle.svg'
import { TArPlatformProductsTilesMenuProps } from './ArPlatformProductsTilesMenu.types'
import classNames from 'classnames'
import useDeviceDetect from 'src/utils/useDeviceDetect'

export const ArPlatformProductsTilesMenu: React.FC<TArPlatformProductsTilesMenuProps> = () => {
  const { isSmallDevice } = useDeviceDetect()
  const [showButton, setShowButton] = useState(false)
  const [scrollContainerNode, setScrollContainerNode] = useState<HTMLDivElement>()

  const update = (node: any) => {
    if (!isSmallDevice || !node) return
    const show = node.scrollLeft === 0 && window.innerWidth < node.scrollWidth
    setShowButton(show)
    setScrollContainerNode(node)
  }

  const scrollToItem = (id: string) => {
    let offset = 0

    switch (id) {
      case 'data-ingestion':
      case 'contract-builder':
      case 'underwriting-workbench':
        offset = -(METRICS.TopNavHeight / 2)
        break
      case 'risk-traging':
      case 'algorithmic-underwriting':
        offset = -(METRICS.TopNavHeight * 1.5)
        break
    }
    scroller.scrollTo(id, {
      duration: 1000,
      smooth: true,
      offset,
    })
  }

  return (
    <div className={s.container}>
      <div
        className={s.scrollWrapper}
        onScroll={(e) => update(e.target)}
        ref={(node) => update(node)}
        id="platform-products-tiles-menu">
        <ul>
          {PLATFROM_SECTIONS.map((item) => (
            <li key={item.id}>
              <button onClick={() => scrollToItem(item.id)}>
                <div className={s.iconTile}>{item.colorfulIcon}</div>
                <span>{item.title}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
      <button
        title="Scroll to element"
        onClick={(e) => {
          if (scrollContainerNode) {
            animateScroll.scrollTo(scrollContainerNode.scrollWidth - scrollContainerNode.clientWidth, {
              duration: 500,
              smooth: true,
              containerId: 'platform-products-tiles-menu',
              horizontal: true,
            })
          }
        }}
        className={classNames(s.actionButton, {
          [s.show]: showButton,
        })}>
        <SvgArrowRightInCircle />
      </button>
    </div>
  )
}
